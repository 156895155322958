.homepage--microsite {

  .intro {

      .neos-contentcollection {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column: auto;
        grid-gap: 15px;


          .neos-nodetypes-image {

              img {
                   height: 170px;
                   width: 100%;
                   object-fit: cover;

                   @include media-breakpoint-down(md) {
                       height: 114px;
                   }
              }

              &:first-child {
                  justify-content: flex-start;
              }

              &:last-child {
                  justify-content: flex-end;
                  padding-right: 0;
              }
          }
      }
  }

  .content {
    margin-top: 50px;
    display: flex;

    .main {
      flex: 0 0 70%;
      display: flex;
    }

    .contact {
      flex: 0 0 30%;
      display: flex;
      background-color: #F9F9F9;
      height: fit-content;
      padding: 15px 20px;
      font-size: 15px;
    }
  }
}
