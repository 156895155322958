input, textarea {
  width: 100%;

  &::-webkit-input-placeholder { /* Edge */
    font-size: $font-size-medium;
    padding-left: 5px;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: $font-size-medium;
    padding-left: 5px;
  }

  &::placeholder {
    font-size: $font-size-medium;
    padding-left: 5px;
  }
}

label {
    &.add-on {
        display: flex;

        input {
            width: auto;
            height: 24px;
            margin-right: 10px;
        }
    }
}

.required {
  font-size: $font-size-medium;
}

.contact-column {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
}

.contact-name {
  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }
}

@include media-breakpoint-up(sm) {
  .contact-firstname {
    padding-right: 0;
  }
}

.contact-submit {
  @include media-breakpoint-up(md) {
    @include make-col(6);
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {

      button.btn-brand-secondary {
        border-radius: 0;
        text-transform: uppercase;
        padding: 0;
      }
    }
  }
}

.form-columns {
    .col-sm-12 {
        h2 {
            font-size: 1rem;
            font-weight: inherit;
        }
    }

    .col-sm-5 {
        .contact-column {
            width: 100%;
            max-width: 100%;
        }

        &:nth-child(2) {
            flex: 0 28%;
            padding-right: 0;
        }

        &:last-child {
            flex: 0 72%;
        }
    }
}

legend {
    font-size: 1rem;
    font-weight: inherit;
}

.contact-column.contact-dsgvo,
.contact-3dmodel {
  display: flex;

  label {
    display: flex;
    align-items: top;

    input {
      width: auto;
      margin-right: 7px;
      margin-top: 3px;
    }

    p {
      font-size: $font-size-small * 1.2;
    }
  }
}

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}

.help-inline {
    color: red;
}

fieldset {
    fieldset {
        margin-bottom: 10px;

        .form-group {
            margin-bottom: 0;

            label {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
