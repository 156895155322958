.neos-nodetypes-headline {
  &.green {
    h1, h2, h3, h4 {
      color: $brand-primary;
    }
  }

  &.h1 {
    h2, h3 {
      @extend h1;
    }

    &.green {
      h1, h2, h3, h4 {
        color: $brand-primary;
      }
    }
  }
}

.neos-nodetypes-text {
    &.more-margin {
        p {
            margin-bottom: 25px;
        }
    }
}


.neos-nodetypes-image {
  img {
    max-width: 100%;
    height: auto;
  }

  figure {
      margin-bottom: 0;
  }

  figcaption {
    p {
      font-style: italic;
    }
  }
}

.instanode-headstart-thumbimage {
  @include media-breakpoint-down(sm){
    width: 100%;
    height: 200px;;
  }
  width: 215px;
  height: 150px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
    height: auto;
  }
}

.jonnitto-prettyembed {
  margin-bottom: 10px;
}
