.breadcrumb-menu {

  .breadcrumb {
    @include make-col(12);
    list-style-type: none;
    display: flex;
    align-items: center;

    li {
      font-size: $font-size-small;
      line-height: 1rem;
      color: $color-gray-medium-light;
      padding: 0;

      &::after {
        padding: 0 5px;
        content: '>'
      }

      a {
        color: $color-gray-medium-light;

        &:hover {
          text-decoration: underline;
        }

      }

      &.current {
        font-family: $font-family-medium;
        font-weight: $font-weight-medium;
      }

      &:first-child {
        display: none;
      }

      &:last-child {
        &::after {
          content: '';
        }
      }
    }
  }

}
