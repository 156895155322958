.teaser-lines {
  @include media-breakpoint-down(sm){
    margin-bottom: 25px;
  }
  @extend .col;
  @extend %responsivnes;

  .teaser-inner {
    border-bottom: 1px solid $color-gray-dark;

    .teaser-image {
      display: flex;
      justify-content: center;
      background-color: $color-gray-lighter;
      height: 100px;

      figure {
        display: contents;
      }
    }

    .teaser-description {
      padding: 0 30px;
    }
  }
}
