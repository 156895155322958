$header-logo-height-md: 60px;
$header-logo-height-xs: $header-logo-height-md/2;

.header {
  @include media-breakpoint-down(sm){
    height: 64px;
  }
  // @extend .container;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 130px;

  .row {
      width: 100%;
  }
}

.header-claim {
  @include media-breakpoint-down(sm){
    top: 10px;
  }
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
  position: absolute;
  width: auto;
  top: 42px;
  left: 0;
  z-index: 1000;

  img {
    @include media-breakpoint-down(sm){
      height: 80px;
    }
    height: 130px;
  }
}

.header-logo {
  @include media-breakpoint-down(sm) {
    // Align logo and toggle
    position: absolute !important;
    right: 10px;
  }

  @include media-breakpoint-up(md) {
    @include make-col(11);
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }



  img {
    height: $header-logo-height-xs;

    @include media-breakpoint-up(md) {
      height: $header-logo-height-md;
    }
  }
}
