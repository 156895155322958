.grid-content {
  @include media-breakpoint-down(sm){
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 15px;
  }
  @extend .container-fluid;
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 30px;

  &:first-of-type {
    @media (max-width: 992px){
      padding: 0 15px 15px 15px;
    }
    margin-top: 0;
    padding: 0 30px 15px 30px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.background-gray {
    @include media-breakpoint-down(sm){
      padding: 35px 15px;
    }
    background-color: $color-gray-light;
    padding: 30px;
  }

  &.check-expert-tool {
    @include media-breakpoint-down(sm){
      .btn-brand-primary {
        display: inline-block;
        text-align: center;
        margin-bottom: 25px;
      }
    }
    background: url('../Images/layout/check-expert-tool.svg') no-repeat right 25px $color-gray-light;
    background-size: 270px;
    min-height: 275px;
  }

  &.mt0 {
    margin-top: 0;
  }

  &.mt25 {
    margin-top: 25px;
  }

  &.mt50 {
    margin-top: 50px;
  }

  &.mb0 {
    margin-bottom: 0;
  }

  &.mb25 {
    margin-bottom: 25px;
  }

  &.mb50 {
    margin-bottom: 50px;
  }
}

.grid-content-col {
  @include media-breakpoint-down(sm){
    margin-top: 15px;
  }

  &.inner-well {
    > .neos-contentcollection {
      @extend .well;
    }
  }

  &.no-wrap {
    > .neos-contentcollection {
      @extend .row;
      flex-wrap: wrap;
      display: flex;
      // justify-content: space-between;

      &::after {
        content: none;
      }
    }
  }

  &.gray {
      background-color: #F9F9F9;
      padding-top: 15px;
      padding-bottom: 15px;
  }
}
