.footer {
  @include make-col(12);
  padding: 10px 0;
  min-height: 60px;
  background-color: $color-gray-medium;

  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
  }

  .footer-content {
    padding: 0;
    @include make-col(12);
    display: flex;
    align-items: center;

  }
}

.footer-nav {
  @include make-col(12);

  @include media-breakpoint-up(sm) {
    @include make-col(8);
  }

  @include media-breakpoint-up(md) {
    @include make-col(10);
  }

  padding: 0 15px;
  margin: 0;

  a {
    color: inherit;

    &:hover {
      color: darken($text-color, 15%);
    }
  }

  .footer-nav-list {
    @include make-col(12);
    list-style-type: none;
    margin-bottom: 0;

    @include media-breakpoint-up(sm) {
      display: flex;
      align-items: center;
      padding-left: 15px;
    }

    li {
      @include media-breakpoint-down(sm){
        margin-bottom: 10px;
      }
      margin-right: 30px;

      a {
        @include media-breakpoint-down(sm){
          font-size: $font-size-base;
        }
        color: $color-white;
        font-family: $font-family-medium;
        font-weight: $font-weight-medium;
        font-size: $font-size-medium;
      }
    }
  }
}

.footer-language-menu {
  @include make-col(12);
  padding-left: 35px;

  @include media-breakpoint-up(sm) {
    @include make-col(8);
    padding-left: 15px;
    margin-left: 15px;
  }

  @include media-breakpoint-up(md){
    @include make-col(2);
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    margin-left: 0;
  }

  ul {
    display: flex;
    margin-bottom: 0;
    align-items: center;
    }

    .footer-language {
        display: flex;
        li {

            margin-right: 10px;

            a {
                display: inline-block;
                height: 15px;
                width: 25px;
                background-size: 25px;
            }

            &:first-child {
                a {
                    background: url('../Images/layout/flag_nl.png') no-repeat;
                }
            }

            &:nth-child(2) {
                a {
                    background: url('../Images/layout/flag_fr.png') no-repeat;
                }
            }

            &:last-child {
                a {
                    background: url('../Images/layout/flag_en.png') no-repeat;
                }
            }
        }
    }
}
