.partnerpage--microsite {

    .subnav--wrapper {
        flex: 0 0 25%;

        @media (max-width: 992px) {
            flex: 0 0 80%;
            display: flex;
            margin-bottom: 15px;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 25px;
            margin-bottom: 0;
        }

        .subnav {
            max-width: 100%;
            flex: 0 0 100%;

            @media (max-width: 992px) {
                max-width: 35%;
                flex: 0 0 35%;
            }

            @include media-breakpoint-down(md) {
                max-width: 50%;
                flex: 0 0 50%;
            }
        }

        .teaser--partner {
            margin-left: 25px;
            max-width: 235px;

            img {
                width: 100%;
                height: auto;
            }

            @include media-breakpoint-down(lg) {
                max-width: 217px;
            }

            @media (max-width: 992px)  {
                height: fit-content;
            }

            @include media-breakpoint-down(sm){
                max-width: 160px;
                margin-left: 10px;
            }

            @media (max-width: 320px) {
                max-width: 142px;
                margin-left: 5px;
            }
        }
    }
}
