.microsite,
.homepage--microsite,
.page--microsite {

  .header-nav-list {

    &.primary {

      li {
        background-color: $color-gray;

        &.current,
        &.active {
          background-color: $brand-primary;
        }

        &:hover {
            background-color: $brand-primary;
        }
      }
    }
  }
}