.teaser-news {
  @include media-breakpoint-down(sm){
    display: block;
  }

  display: flex;
  flex-wrap: nowrap;
  border-top: 2px solid $color-white;
  padding: 25px 0 20px 0;

  &:last-of-type {
    border-bottom: 2px solid $color-white;
  }

  &.image {
    border-top: none;
    border-bottom: 1px solid $color-gray-medium-light;
    padding: 0 0 20px 0;
    margin-bottom: 45px;

    .teaser-conent {
      @include media-breakpoint-down(sm) {
        @include make-col(12);
      }
      @include make-col(6);
    }

    .teaser-button {
      display: flex;
      justify-content: flex-end;
      @include make-col(6);

      .btn-brand-primary.inline-block,
      .btn-brand-secondary.inline-block {
        margin-right: 0;
      }
    }
  }

  .teaser-conent {
    @include media-breakpoint-down(sm){
      @include make-col(12);
      display: block;
    }
    @include make-col(9);

    h3 {
      font-size: $font-size-large * 1.1;
      font-family: $font-family-medium;
      font-weight: $font-weight-medium;
    }

    p {
      margin-bottom: 0;
    }
  }

  .teaser-button {
    @include media-breakpoint-down(sm){
      @include make-col(12);
      display: block;
    }
    @include make-col(3);
    padding-top: 8px;
  }
}
