.teaser--partner {
  border: 1px solid #ebebeb;
  width: 245px;
  float: left;
  padding: 10px 3px;

  @include media-breakpoint-down(md) {
    width: 218px;
  }

  figure {
    margin-bottom: 0;

    img {
      max-width: 100%;
    }
  }
}
