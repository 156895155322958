.callout {
  @include media-breakpoint-down(sm) {

  }

  // @extend .container;
  padding: 0;
  margin: 0 auto !important;
  transition: all 0.3s;



  .carousel-item {
    @include media-breakpoint-down(sm) {
    height: 88px;
    }
    height: 265px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $brand-secondary;
    opacity: 1;
    animation: fade .5s linear;


    @keyframes fade {
        0% { opacity: 0 }
        100% { opacity: 1 }
      }
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  display: none;
  position: absolute;
  padding: 20px;
  background-color: $callout-caption-bgcolor;
  color: $brand-primary;

  @include media-breakpoint-up(sm) {
    display: block;
    left: 15%;
    top: 40%;
    width: 350px;
  }
}
