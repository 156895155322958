.hero {
  @include make-col(12);
}

.webinar-header {
  background: url('../Images/layout/hero-webinar.png') no-repeat;
  min-height: 250px;
  background-size: cover;
  padding: 30px 30px 35px 65px;

  .webinar-header-label {
    margin-left: 0;
    color: #D8D8DA;
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-family-light;
    font-weight: $font-weight-light;
    font-size: 17px;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 20px;
  }

  .webinar-header-title {
    font-family: $font-family-medium;
    font-weight: $font-weight-medium;
    color: $color-white;
    margin-top: 0;
    font-size: 24px;
    line-height: 28px;
  }

  .webinar-header-description {
    color: $color-white;
    font-family: $font-family-medium;
    font-weight: $font-weight-medium;
    @include make-col(10);
  }
}
