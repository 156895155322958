.product-item {
  @extend .col;
  @include make-col(12);
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 30px;
  border-bottom: 1px solid $color-gray;

  &:last-child{
    border: none;
  }

  .link-back {
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      padding-left: 40px;
    }
  }

 //neu
  .product-desc-head {
    @include media-breakpoint-down(sm) {
      padding: 15px;
    }

    .title {
      .content {
          @include media-breakpoint-down(sm) {
          display: inline-block;
          left: 160px;
        }
        display: inline-flex;
        height: 25px;
        position: absolute;
        left: 200px;

        &.branch {
          color: $color-green;
          text-transform: uppercase;

          p {
            display: inline-block;

            &::after {
            content: ' | ';
            padding-right: 9px;
            padding-left: 9px;
          }

          &:last-child{
            &::after {
                content: ' ';
                padding: 0;
              }
            }
          }
        }

        p {
          @include media-breakpoint-down(sm) {
            display: block;
          }
          height: 25px;

          a {
            color: $text-color;
            text-decoration: underline;

            &:hover {
              color: $brand-primary;
            }
          }
        }
      }
    }
  }

  .product-desc-main {
    margin-left: -15px;
    @include make-col(7);
    @include media-breakpoint-down(sm) {
      margin-left: 0;
    }

    .product-desc-info {
      i {
        font-style: normal;
        &::after{
          content: " ";
          background-image: url('../Images/layout/EX-Logo.png');
          background-size: auto 16px;
          background-repeat: no-repeat;
          background-position: 13px 5px;
          width: 31px;
          height: 21px;
          display: inline-block;
        }
      }
    }

    .title {
      .content {
        @include media-breakpoint-down(sm) {
        display: inline-block;
        left: 160px;
      }
      display: inline-flex;
      height: 25px;
      position: absolute;
      left: 200px;
      }
    }
  }



  .product-desc-image {
    @include media-breakpoint-down(sm) {
      display: block;
      @include make-col(12);
    }
    @include make-col(3);
    display: flex;
    justify-content: center;
    align-items: flex-end;

    img {
      margin: 15px 0;
      width: 100%;
    }
  }
}
