.partner--images {
    display: flex;

    @include media-breakpoint-down(sm) {
        display: block;
        img {
            margin-bottom: 15px;
        }
    }

    img {
        margin-right: 15px;
        width: 32%;
        height: auto;


        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &:last-child {
            margin-right: 0;
        }

        &.border {
            border: 1px solid black !important;
        }
    }
}
